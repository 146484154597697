import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Verify from './Verify'
import VerifyOrderPayment from './VerifyOrderPayment'
import VerifyDeposit from  './VerifyDeposit'
function App() {
  return (
    <Router>
    <Routes>
      {/* Define route with :id param */}
      <Route path="/verify/:id" element={<Verify/>} />
      <Route path="/VerifyOrderPayment/:id" element={<VerifyOrderPayment/>} />
      <Route path="/VerifyDeposit" element={<VerifyDeposit/>} />
    </Routes>
  </Router>
  );
}

export default App;
