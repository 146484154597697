import React, { Component } from "react";
import "./Invoicecard.css"; // Optional: Add your styles here

// Invoice_No: "Xk_jeeJ67W2BOAS"
// __v: 0
// ​​_id: "66f834f4d60c8e534c91b428"
// ​​cont_payment_amount: "50000.00"
// ​​createdAt: "2024-09-28T16:55:16.522Z"
// ​​createdBy: "66dcb37619b0a1bb3c77f13c"
// ​​created_at: "2024-09-28T16:55:16.525Z"
// ​​currentpaymentInterval: "1"
// ​​ekubAmount: 500000
// ​​member: "66dcb37619b0a1bb3c77f13c"
// ​​paymentInterval: "2"
// ​​paymentstatus: "pending"
// ​​updated_at: "2024-09-28T16:55:16.525Z"

const Paymentorder = ({ ekubPayments }) => {
  return (
    <div className="invoice-card">
       <p>
        <strong> cont payment amount:</strong>{" "}
        {ekubPayments.cont_payment_amount}
      </p>
      <p>
        <strong>current payment Interval:</strong>{" "}
        {ekubPayments.currentpaymentInterval}
      </p>
      <p>
        <strong>ekub Amount:</strong> {ekubPayments.ekubAmount}{" "}
      </p>
      <p>
        <strong>​payment status:</strong> {ekubPayments.paymentstatus}
      </p>


      <p>
        <strong>Created At:</strong>{" "}
        {new Date(ekubPayments.created_at).toLocaleString()}
      </p>
      <p>
        <strong>Updated At:</strong>{" "}
        {new Date(ekubPayments.updated_at).toLocaleString()}
      </p>
    </div>
  );
};

export default Paymentorder;
