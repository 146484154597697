import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Invoicecard from "./Invoicecard";
import Paymentorder from "./Paymentorder";
import axios from "axios";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Verify = () => {
  const { id } = useParams(); // Get 'id' from URL
  const query = useQuery();
  const invoiceNo = query.get("Invoice_No");
    const [ekubPayments, setEkubPayments] = useState([]);


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  console.log({ invoiceNo });
  console.log({ id });

  const invoice = {
    _id: ekubPayments?.member?._id,
    Invoice_No: invoiceNo,
    currency: "ETB",
    user: ekubPayments?.member?._id,
    amount: ekubPayments?.cont_payment_amount,
    email: ekubPayments?.member?.email,
    first_name: ekubPayments?.member?.firstname,
    last_name: ekubPayments?.member?.lastname,
    phone_number: ekubPayments?.member?.phone,
    tx_ref: id,
    status: "success",
  };
  useEffect(() => {
    const fetchEkubPayments = async () => {
      if (!invoiceNo) return; // Don't fetch if no invoice number

      setLoading(true); // Set loading state
      setError(''); // Reset error state

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/ekubpayments/view-ekub-payment-by-invoice-no`,
          { Invoice_No: invoiceNo },
          { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` } }
        );

        setEkubPayments(response.data[0]); // Set the first payment details
      } catch (err) {
        console.error('Error fetching Ekub payments:', err);
        setError('Failed to fetch payments. Please try again.');
        throw err; // Propagate error to the caller
      }
    };

    const patchEkubPayments = async () => {
      if (!ekubPayments) return;

      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/ekubpayments/update-ekub-payment`,
          { _id: ekubPayments._id, paymentstatus: 'paid' },
          { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` } }
        );

        console.log('Updated Ekub payment:', response.data);
      } catch (err) {
        console.error('Error updating Ekub payments:', err);
        setError('Failed to update payments. Please try again.');
        throw err;
      }
    };

    const saveChapa = async () => {
      if (!ekubPayments?.member) return;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/chapas/save_chapa`,
          {
            Invoice_No: invoiceNo,
            currency: 'ETB',
            user: ekubPayments.member?._id,
            amount: ekubPayments.cont_payment_amount,
            email: ekubPayments.member?.email,
            first_name: ekubPayments.member?.firstname,
            last_name: ekubPayments.member?.lastname,
            phone_number: ekubPayments.member?.phone,
            tx_ref: 'Xk_jeeJ67W2BOAS', // Replace with your desired tx_ref
            status: 'success',
          },
          { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` } }
        );

        console.log('Saved Chapa response:', response.data);
      } catch (err) {
        console.error('Error saving Chapa:', err);
        setError('Failed to save Chapa. Please try again.');
        throw err;
      }
    };

    const handlePayments = async () => {
      try {
        await fetchEkubPayments();
        await patchEkubPayments();
        await saveChapa();
      } catch (err) {
        console.error('Error in handlePayments:', err);
      } finally {
        setLoading(false); // Ensure loading state is reset
      }
    };

    handlePayments();
  }, [invoiceNo, ekubPayments]);


  console.log({ ekubPayments });

  console.log(ekubPayments?.member?._id);

  const [verificationData, setVerificationData] = useState(null);
  // for chapa collection

  // {
  //     _id: mongoose.Schema.Types.ObjectId,
  //     Invoice_No: {
  //       type: mongoose.Schema.Types.ObjectId,
  //       ref: "Ekubpayment",
  //       required: true,
  //     },
  //     currency: { type: String, required: true },
  //     user: { type: String, required: true },
  //     account: { type: String },
  //     amount: { type: String, required: true },
  //     email: { type: String, required: true },
  //     first_name: { type: String, required: true },
  //     last_name: { type: String, required: true },
  //     phone_number: { type: Number, required: true },
  //     tx_ref: { type: String, required: true, unique: true },

  //     status: { type: String, enum: ["success", "pending"], default: "pending" },
  //   },

  // console.log(ekubPayments.member)

  // task one save invoice to chapa

  // update payment to success

  return (
    <div>
      {ekubPayments == null ? (
        ""
      ) : (
        <>
          <Invoicecard invoice={invoice} ekubPayments={ekubPayments} />
          <Paymentorder ekubPayments={ekubPayments}></Paymentorder>
        </>
      )}
    </div>
  );
};

export default Verify;
