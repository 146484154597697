import React from 'react';
import './Invoicecard.css';  // Optional: Add your styles here

const Invoicecard = ({ invoice }) => {


    // "_id": "66dcb37619b0a1bb3c77f13c",
    // "photo": "",
    // "firstname": "useron",
    // "lastname": "userone",
    // "phone": "0911303007",
    // "email": "userone@gmail.com",
    // "woreda": "",
    // "subcity": "",
    // "city": "",
    // "emergency_contact_name": "",
    // "emergency_contact_phone": "",
    // "emergency_contact_id": "",
    // "password": "$2a$10$9sd1ErYeqekrfHfyXvHg.eq2jxydX5QdaWROHOFOxm1mmaPuZvBQu",
    // "usertype": "user",
    // "role": "user",
    // "organizer": "approved",
    // "accountstatus": "verified",
    // "__v": 0






  return (
    <div className="invoice-card">
      <h3>Invoice #{invoice.Invoice_No}</h3>
       <p><strong>Email:</strong> {invoice.email}</p>
      <p><strong>Name:</strong> {invoice.first_name} {invoice.last_name}</p>
      <p><strong>Phone Number:</strong> {invoice.phone_number}</p>
      <p><strong>Amount:</strong> {invoice.amount} {invoice.currency}</p>
      <p><strong>Status:</strong> {invoice.status}</p>
      <p><strong>Transaction Ref:</strong> {invoice.tx_ref}</p>
      <p><strong>Account:</strong> {invoice.account ? invoice.account : 'N/A'}</p>
      {/* <p><strong>Created At:</strong> {new Date(invoice.created_at).toLocaleString()}</p> */}
      {/* <p><strong>Updated At:</strong> {new Date(invoice.updated_at).toLocaleString()}</p> */}
    </div>
  );
};

export default Invoicecard;
