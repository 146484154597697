import React, { Component } from 'react'
import { useLocation } from 'react-router-dom';
const VerifyDeposit = () => {
    const query = new URLSearchParams(useLocation().search);

    // Access the parameters
    const email = query.get('email');
    const firstName = query.get('first_name');
    const lastName = query.get('last_name');
    const phoneNumber = query.get('phone_number');
    const txRef = query.get('tx_ref');
    const amount = query.get('amount');
    const currency = query.get('currency');
    const user = query.get('user');
    const account = query.get('account');


    console.log(query)





     return (
         <view>
             VerifyDeposit
       </view>
    )

}

export default VerifyDeposit
