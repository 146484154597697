import React, { useEffect } from 'react';

const VerifyOrderPayment = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      // Redirect to the custom URL scheme
      window.location.href = 'myapp://Chapapay';
    }, 2000); // Redirect after 2 seconds

    // Cleanup timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      Verifying Order Payment... Please wait.
    </div>
  );
};

export default VerifyOrderPayment;
